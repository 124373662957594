<template>
  <div class="teacher">
    <el-form class="search" :inline="true" :model="query">
      <el-form-item label="评价状态:">
        <el-select v-model="query.status" clearable filterable class="select">
          <el-option v-for="item in statusOptions" :key="item.code" :label="item.name" :value="item.code"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="评价时间:">
        <el-date-picker class="date" v-model="sdate" type="datetimerange" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="dateChange"> </el-date-picker>
      </el-form-item>
      <el-form-item label="教师姓名:">
        <el-input class="input" placeholder="输入教师姓名" v-model.trim="query.ratabledName" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <!-- <el-button
          @click="resetForm"
          >重置</el-button
        > -->
        <el-button type="primary" @click="search" class="button">查询</el-button>
        <el-button class="button" plain type="primary" @click="goBack()">返回</el-button>
      </el-form-item>
    </el-form>
    <!-- 主体内容  @tab-click="tabBarChange"-->
    <el-tabs v-model="activeName" class="types-tab">
      <el-tab-pane v-for="(item, index) in tabBar" :key="item.weightId" :data-id="item.weightId" :name="String(index)" :label="item.weightMethod"></el-tab-pane>
    </el-tabs>
    <div class="info">
      <el-table v-loading="loading" :data="tableList" style="width: 100%" :header-cell-style="{ background: '#eff3ff', color: '#5799f4' }" :row-style="{ background: '#fafafa' }">
        <el-table-column type="index" width="80" label="序号" align="center"> </el-table-column>
        <el-table-column prop="ratabledName" label="被评人" align="center"> </el-table-column>
        <el-table-column prop="createTime" label="评价时间" align="center"> </el-table-column>
        <el-table-column prop="status" label="评价状态" align="center">
          <template slot-scope="scope">
            <el-tag :type="scope.row.status | statusFilter">
              {{ scope.row.status === '1' ? '已评价' : '未评价' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="answerTotalScore" label="评价得分" align="center"> </el-table-column>
        <el-table-column prop="caozuo" label="操作" align="center">
          <template slot-scope="scope" class="status code1">
            <el-button @click.native.prevent="deleteRow(scope.row.ratabledId)" type="text" size="medium" :class="{ code1: scope.row.status }">
              {{ scope.row.status == false ? '进行评价' : '查看详情' }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination v-if="pages > 1" ref="pagination" :page-size="query.limit" :current-page.sync="query.page" :total="total" layout="total,prev, pager, next, jumper" background prev-text="上一页" next-text="下一页" @current-change="handleCurrentChange"> </el-pagination>
  </div>
</template>
<script>
import api from '@/api/teacherEvat';
const statusOptions = [
  { code: 2, name: '请选择' },
  { code: 0, name: '未评价' },
  { code: 1, name: '已评价' },
];
let briefCurIndex = null;
export default {
  data() {
    return {
      query: {
        limit: 10,
        page: 1,
        status: '',
        startTime: '',
        endTime: '',
        title: '',
        ratabledName: '',
      },
      total: 0,
      pages:'',
      activeName: '0',
      statusOptions,
      sdate: '',
      paperId: '',
      loading: true,
      weightId: '',
      tabBar: [],
      tableList: [],
    };
  },
  mounted() {
    this.paperId = this.$route.query.paperId;
    this.getTabBar();
  },
  filters: {
    statusFilter(status) {
      const statusMap = { 0: 'danger', 1: 'success' };
      return statusMap[status];
    },
  },
  methods: {
    dateChange(val) {
      if (val) {
        this.query.startTime = val[0];
        this.query.endTime = val[1];
        this.query.status = 1;
      } else {
        this.query.startTime = '';
        this.query.endTime = '';
        this.query.status = 2;
      }
    },
    handleCurrentChange(val) {
      this.query.page = val;
      briefCurIndex = val;
      this.fetchData();
    },
    // 方式
    getTabBar() {
      api.getType({ paperId: this.paperId }).then(res => {
        this.tabBar = res.result;
        this.weightId = this.tabBar[0].weightId;
        this.fetchData();
      });
    },
    // tabBarChange(tab) {
    //   this.weightId = tab.$attrs['data-id'];
    //   // this.curIndex = e;
    //   // this.query.page = 1;
    //   this.fetchData();
    // },
    // 获取数据
    fetchData() {
      if(briefCurIndex){
        
        this.query.page = briefCurIndex
        // this.$refs.pagination.internalCurrentPage = briefCurIndex
        // console.log(this.$refs.pagination.,'======')
      }
      var params = {
        paperId: this.paperId,
        status: this.query.status,
        ratabledName: this.query.ratabledName,
        page: this.query.page,
        limit: this.query.limit,
        startTime: this.query.startTime,
        endTime: this.query.endTime,
        weightId: this.weightId,
      };
      this.loading = true;
      api.getAllRataList(params).then(res => {
        if (res.success) {
          this.tableList = res.result.records;
          this.total = res.result.total;
          this.loading = false;
          this.pages = res.result.pages;
          // this.list = response.result.ratabledList[0].ratableds.records;
          // if (this.list.length > 0) {
          // } else {
          //   this.method = "被评人";
          // }
          // this.list.forEach((item) => {
          //   this.weightId = item.weightId;
          // });
          // this.unRevieweds = this.list.filter(
          //   (item) => item.createTime === null
          // );
          // this.revieweds = this.list.filter((item) => item.createTime !== null);
          // this.total = response.result.ratabledList[0].ratableds.total;
          // this.pages = response.result.ratabledList[0].ratableds.pages
          // // 返回首页对应页码
          // this.show = false;
          // this.$nextTick(() => {
          //   this.show = true;
          // });
        }
      });
    },
    // 搜索
    search() {
      this.query.page = '';
      this.fetchData();
    },
    // 返回
    goBack() {
      this.$router.push({
        name: 'Teacher',
      });
    },
    // 跳转详情
    deleteRow(e) {
      this.$router.push({
        name: 'TeacherDetail',
        query: {
          paperId: this.paperId,
          ratabledId: e,
          weightId: this.weightId,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/mixin.scss';
.el-button {
  border: none;
}
.teacher {
  min-height: calc(100vh - 264px);
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 20px;
}
.search {
  color: #585f6c;
  .select {
    width: 140px;
  }
  /deep/.el-input__icon {
    font-size: 17px;
  }
  .el-select-dropdown__item {
    font-size: 14px;
  }
}
.info {
  @include border();
  border-radius: 6px;
  padding: 21px 18px 26px 15px;
  /deep/.el-table {
    background-color: #fafafa;
    .cell {
      font-size: 16px;
    }
    .el-table__header {
      .cell {
        font-size: 16px;
      }
    }
    .el-button--text {
      font-size: 16px;
    }
    .status {
      color: #4691f3;
      .code0 {
        color: #4691f3;
      }
      .code1 {
        color: #fa4f33;
      }
    }
  }
}
.el-tag {
  font-size: 14px;
}
.el-pagination{
  text-align: right;
  padding: 20px 0;
}
.types-tab{
  /deep/ .el-tabs__item{
    font-size: 16px;
    font-weight: 600;
  }
}
</style>
