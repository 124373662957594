import {
  request
} from '@/utils/request'
export default {
  search(data) {
    return request({
      url: `/evat/paper/stuEvatList`,
      method: 'get',
      params: data
    })
  },
  getAnalyNum(data) {
    return request({
      url: `/evat/paper/stuEvatAnalysisNum`,
      method: 'get',
      params: data
    })
  },
  // 显示题目
  getList(data) {
    return request({
      url: `/evat/paper/getStuEvatVo`,
      method: 'get',
      params: data
    })
  },
  // 提交答案
  save(data) {
    return request({
      url: `/evat/answer/stuSaveEvatAnswer`,
      method: 'post',
      // header: {
      //     'content-type': 'application/json'
      // },
      data
    })
  },
  getType(data){
    return request({
      url: `/evat/paper/getEvatMethod`,
      method: 'get',
      params: data
    })
  },
  getAllRataList(data) {
    return request({
      url: `/evat/paper/getStuEvatAllRatabledsList`,
      method: 'get',
      params: data
    })
  },
  // 显示已评未评
  getRataList(data) {
    return request({
      url: `/evat/paper/getStuEvatRatabledsList`,
      method: 'get',
      params: data
    })
  },
  loadList(data) {
    return request({
      url: `/evat/paper/getStudentEvatVo`,
      method: 'get',
      params: data
    })
  },
  // 提交答案
  save1(data) {
    return request({
      url: `/evat/answer/studentSaveEvatAnswer`,
      method: 'post',
      data
    })
  },
}